export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        // development: "http://127.0.0.1:8000",
        // production: "http://127.0.0.1:8000", 
        development: "https://worklwalk.myappscenter.com",
        production: "https://worklwalk.myappscenter.com", 
    },
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__lwalkUser",
      ACCESS_TOKEN: "__lwalkToken",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm",
      DEF_FB : "__defFb", 
      OUTLET_LIST : '_outletList',
      STOCK_OPNAME_PARAMS : '_soParams',
      LR_DATA : '_lrData',
      NR_DATA : '_nrData',
    },
    cookiesName : 'lwalk',
    expiresSession : 86400,
    itemPerPage : 25,
    timeOutValue : 10000,
    endPoint : {
      // auth
      permissions : '/auth/api/v1/permissions/',
      groupPermissions : '/auth/api/v1/group-permissions/',
      postGroupPermissions : '/auth/api/group-permissions',
      putGroupPermissions : '/auth/api/group-permissions/',

      // laporan
      laporanPenerimaanKasir : '/accounting/api/v1/laporan/penerimaan-kasir/',

      // setting
      // accounting      
      account : '/accounting/api/v1/account/',
      accountByGroup : '/accounting/api/v1/account-by-group/',
      accountResume : '/accounting/api/v1/account-resume/',
      bank : '/accounting/api/v1/bank/',
      defaultAccount : '/accounting/api/v1/default-account/',
      jurnal : '/accounting/api/v1/jurnal/',
      jurnalManual : '/accounting/api/v1/jurnal-manual/',
      labaRugi : '/accounting/api/v1/laba-rugi/',
      neraca : '/accounting/api/v1/neraca/',
      tahunJurnal : '/accounting/api/v1/tahun-jurnal/',
      natureList : '/accounting/api/v1/list-nature/',
      masterShift : '/master/api/v1/master-shift/',
      
      // backoffice
      hutang : '/accounting/api/v1/hutang/',
      unpostingHutang : '/accounting/api/v1/unposting-hutang/',
      hutangBayarByInvoice : '/accounting/api/v1/bayar-hutang-byinvoice/',
      hutangBayarByKontak : '/accounting/api/v1/bayar-hutang-bykontak/',
      piutang : '/accounting/api/v1/piutang/',
      unpostingPiutang : '/accounting/api/v1/unposting-piutang/',
      piutangTerimaByInvoice : '/accounting/api/v1/terima-piutang-byinvoice/',
      piutangTerimaByKontak : '/accounting/api/v1/terima-piutang-bykontak/',
      // hutangDetail : '/accounting/api/v1/hutang-detail/',

      // deposite
      deposite : `/accounting/api/v1/deposite/`,
      depositeResume : `/accounting/api/v1/deposite-resume/`,
      depositeDetail : `/accounting/api/v1/deposite-detail/`,
      depositeRefund : `/accounting/api/v1/deposite-refund/`,

      // hrd
      hariLibur : '/hrd/api/v1/hari-libur/',
      absen : '/hrd/api/v1/absen-pegawai/',
      jabatan : '/hrd/api/v1/jabatan/',
      department : '/hrd/api/v1/department/',
      pegawai : '/hrd/api/v1/pegawai/',
      suratPeringatan : '/hrd/api/v1/pegawai-sp/',
      salahInput : '/hrd/api/v1/pegawai-salah-input/',

      // master
      hari : '/master/api/v1/master-hari/',
      shift : '/master/api/v1/master-shift/',

      // penjualan
      masterOutlet : '/penjualan/api/v1/master-outlet/',
      penjualan : `/penjualan/api/v1/penjualan/`,
      penjualanResumeOutlet : `/penjualan/api/v1/resume-penjualan-byoutlet/`,
      penjualanResumeKategori : `/penjualan/api/v1/resume-penjualan-kategori/`,
      penjualanResumeTanggal : `/penjualan/api/v1/resume-penjualan-bydate/`,
      penjualanRetur : `/penjualan/api/v1/retur-penjualan/`,
      penjualanResumeOutletKasir : `/penjualan/api/v1/resume-penjualan-outlet-kasir/`,
      penjualanResumeByProduk : `/penjualan/api/v1/resume-penjualan-byproduk/`,

      // perusahaan
      perusahaan : '/master/api/v1/perusahaan/',

      // pembelian
      pembelian : '/pembelian/api/v1/pembelian/',
      pembelianRetur : '/pembelian/api/v1/pembelian-retur/',

      // pengeluaran
      pengeluaran : '/accounting/api/v1/pengeluaran/',
      pengeluaranPosting : '/accounting/api/v1/posting-pengeluaran/',
      pengeluaranUnPosting : '/accounting/api/v1/unposting-pengeluaran/',

      // inventory
      // outlet : '/master/api/outlet/',
      hargaJualCustom : '/produk/api/v1/harga-jual-custom/',
      kategori : '/produk/api/v1/kategori/',      
      produk : '/produk/api/v1/produk/',
      produkSatuan : '/produk/api/v1/produk-satuan/',
      produkAccount : '/produk/api/v1/produk-account/',
      satuan : '/produk/api/v1/satuan/',
      satuanWaktu : '/produk/api/v1/satuan-waktu/',
      satuanWaktuTipeConvert : '/produk/api/v1/tipe-convert-satuanwaktu/',
      tipeHarga : '/produk/api/v1/tipe-harga/',
      produkLogFifo : '/produk/api/v1/produk-log-fifo/',

      
      // mutasi
      mutasi : '/produk/api/v1/mutasi/',
      mutasiDetail : 'produk/api/v1/mutasi-detail/',
      mutasiApprove : 'produk/api/v1/approve-mutasi/',

      // member
      masterMember : '/member/api/v1/master-member/',
      member : '/member/api/v1/member/',
      memberDetail : '/member/api/v1/member-detail/',
      memberLog : '/member/api/v1/member-log/',
      jenisPembayaran : '/member/api/jenis-pembayaran/',

      // kontak
      kontak : '/kontak/api/v1/kontak/',

      // location
      lokasi : '/master/api/v1/lokasi/',
      changePassword : `/auth/api/change-password`,

      
    }
};
  